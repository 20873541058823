import type {
  BillingInterval,
  Plan,
  Promotion,
  PromotionDiscount,
  PromotionPhase,
  PromotionSchedule,
  PromotionScheduleType,
} from '~/utils/rollerup.server';
import { add, differenceInMonths, endOfDay, format } from 'date-fns';

// An AppliedPromotionPhase is the result of applying a set of promotions to a
// plan for a given span of time
export interface AppliedPromotionPhase extends PromotionPhase {
  durationDescription: string;
  promotionIds: string[];
  price: number;
  appliedDiscounts: PromotionDiscount[];
}

export interface AppliedDiscount {
  price: number;
  appliedDiscounts: PromotionDiscount[];
}
export function applyDiscounts(
  discounts: PromotionDiscount[],
  price: number,
): AppliedDiscount {
  const discountAmount = ({ type, value }: PromotionDiscount) => {
    return type === 'percent' ? (price * value) / 100 : value;
  };

  const uniqueDiscountsByStrategy = (
    strategy: PromotionDiscount['combineStrategy'],
  ) =>
    discounts
      .filter(({ combineStrategy }) => combineStrategy == strategy)
      .reduce((set, d) => {
        if (
          set.some(({ stripeCouponId }) => stripeCouponId === d.stripeCouponId)
        ) {
          return set;
        }
        return [...set, d];
      }, [] as PromotionDiscount[])
      .sort((a, b) => a.stripeCouponId.localeCompare(b.stripeCouponId));

  const appendDiscounts = uniqueDiscountsByStrategy('append');
  const result = {
    totalDiscountAmount: appendDiscounts.reduce(
      (sum, d) => sum + discountAmount(d),
      0,
    ),
    discounts: appendDiscounts,
  };

  const maxDiscounts = uniqueDiscountsByStrategy('max');
  for (const discount of maxDiscounts) {
    const amount = discountAmount(discount);
    if (amount > result.totalDiscountAmount) {
      result.totalDiscountAmount = amount;
      result.discounts = [discount];
    }
  }

  return {
    price: Math.max(0, price - result.totalDiscountAmount),
    appliedDiscounts: result.discounts,
  };
}

export function applyPromotions(
  plan: Plan,
  anchor?: Date,
): AppliedPromotionPhase[] {
  if (!anchor) {
    // If we're previewing a plan use the end of day as the billing anchor, in
    // attempt to avoid showing a fixed_date promotion that might expire before
    // the user actually subscribes. Could be improved.
    anchor = endOfDay(new Date());
  }
  const promotionPhases: AppliedPromotionPhase[] = [];

  // Filter out any promotions that are not active
  // TODO: account also for startDate/endDate if set (should probably be
  // done server-side though)
  const promotions = (plan.promotions ?? []).reduce<Promotion[]>((set, p) => {
    if (!p.active || set.some(({ id }) => id === p.id)) return set;
    return [...set, p];
  }, []);

  if (promotions.length === 0) {
    return promotionPhases;
  }

  let durationDescriptor = 'First';
  const durationDescription = (
    {
      type,
      interval,
    }: { type: PromotionScheduleType; interval?: BillingInterval },
    phase: PromotionPhase & { durationDescription?: string },
  ) => {
    const descriptor =
      phase.durationDescription?.split(' ')?.[0] || durationDescriptor;
    durationDescriptor = 'Next';
    if (type === 'ongoing') {
      return 'Forever';
    }
    if (type === 'fixed_duration') {
      const { intervalCount } = phase;
      if (!intervalCount) {
        throw new Error(
          'intervalCount is required for fixed_duration schedules',
        );
      }
      if (intervalCount > 1) {
        return `${descriptor} ${intervalCount} ${interval}s`;
      } else {
        return `${descriptor} ${interval}`;
      }
    }
    if (type === 'fixed_dates') {
      const fmt = 'MMM yyyy';
      const startDescrption = phase.startDate
        ? format(new Date(phase.startDate), `From ${fmt}`)
        : '';
      const endDescription = phase.endDate
        ? format(new Date(phase.endDate), `Until ${fmt}`)
        : '';
      return `${startDescrption} ${endDescription}`.trim();
    }
    throw new Error('Unsupported promotion schedule');
  };

  const shiftPromotions = promotions.filter(
    (p) => p.promotionSchedule.combineStrategy === 'shift',
  );
  let intervalCount = 0;
  for (const promotion of shiftPromotions) {
    for (const phase of promotion.promotionSchedule.phases) {
      if (promotion.promotionSchedule.type === 'fixed_duration') {
        intervalCount += phase.intervalCount ?? 0;
      } else {
        // For now all shift promotions must be fixed_duration
        throw new Error('Unsupported promotion schedule type for shift');
      }
      const appliedDiscount = applyDiscounts(phase.discounts, plan.basePrice);
      promotionPhases.push({
        ...phase,
        ...appliedDiscount,
        durationDescription: durationDescription(
          promotion.promotionSchedule,
          phase,
        ),
        promotionIds: [promotion.id].filter(Boolean) as string[],
      });
    }
  }

  // Apply stacking promotions interval-by-interval
  const stackPromotions = promotions.filter(
    (p) => p.promotionSchedule.combineStrategy !== 'shift',
  );
  const stackPhaseStartDate = add(anchor, { months: intervalCount });

  // Check if a phase is active at a given date
  const phaseIsActive = (
    schedule: PromotionSchedule,
    phaseIndex: number,
    date: Date,
  ) => {
    const phase = schedule.phases[phaseIndex];
    if (schedule.type === 'fixed_dates') {
      if (phase.startDate && date < new Date(phase.startDate)) {
        return false;
      }
      if (phase.endDate && date >= new Date(phase.endDate)) {
        return false;
      }
      return true;
    }
    if (schedule.type === 'fixed_duration') {
      const startDate = schedule.phases
        .slice(0, phaseIndex)
        .reduce((date, p) => {
          return add(date, { months: p.intervalCount });
        }, stackPhaseStartDate);
      const endDate = add(startDate, { months: phase.intervalCount });
      if (date < startDate) {
        return false;
      }
      if (date >= endDate) {
        return false;
      }
      return true;
    }
    // Ongoing schedule type
    return true;
  };
  const intervalCounts = stackPromotions.map(({ promotionSchedule }) => {
    if (promotionSchedule.type === 'fixed_duration') {
      return promotionSchedule.phases.reduce(
        (duration, { intervalCount }) => duration + (intervalCount ?? 0),
        0,
      );
    }
    if (promotionSchedule.type === 'fixed_dates') {
      const maxTime = promotionSchedule.phases.reduce(
        (max, { endDate, startDate }) => {
          return Math.max(max, new Date(endDate ?? startDate ?? 0).getTime());
        },
        0,
      );
      return differenceInMonths(new Date(maxTime), stackPhaseStartDate);
    }
    // Ongoing schedule type will be applied over at least one phase
    return 1;
  });
  const maxIntervalCount = Math.max(...intervalCounts) + intervalCount;

  let lastDiscounts: string | undefined;
  for (; intervalCount < maxIntervalCount; intervalCount++) {
    const billingDate = add(anchor, { months: intervalCount });
    const activePromotions = stackPromotions.filter(({ promotionSchedule }) => {
      return promotionSchedule.phases.some((_, idx) =>
        phaseIsActive(promotionSchedule, idx, billingDate),
      );
    });
    const promotionIds = activePromotions
      .map(({ id }) => id)
      .filter(Boolean) as string[];
    const activePhases = activePromotions.reduce(
      (phases, { promotionSchedule }) => {
        return [
          ...phases,
          ...promotionSchedule.phases.filter((phase, idx) => {
            return phaseIsActive(promotionSchedule, idx, billingDate);
          }),
        ];
      },
      [] as PromotionPhase[],
    );
    const discounts = activePhases.map((p) => p.discounts).flat();
    const { price: discountedPrice, appliedDiscounts } = applyDiscounts(
      discounts,
      plan.basePrice,
    );

    const currentDiscounts = JSON.stringify(appliedDiscounts.sort());
    if (lastDiscounts === currentDiscounts) {
      const lastPhase = promotionPhases[promotionPhases.length - 1];
      lastPhase.intervalCount = lastPhase.intervalCount! + 1;
      lastPhase.durationDescription = durationDescription(
        { type: 'fixed_duration', interval: 'month' },
        lastPhase,
      );
    } else if (intervalCount < maxIntervalCount) {
      const phase: PromotionPhase = {
        discounts: appliedDiscounts,
        intervalCount: 1,
      };
      promotionPhases.push({
        ...phase,
        price: discountedPrice,
        durationDescription: durationDescription(
          { type: 'fixed_duration', interval: 'month' },
          phase,
        ),
        appliedDiscounts,
        promotionIds,
      });
    }
    lastDiscounts = currentDiscounts;
  }

  // Final phase restores the original price. This is necessary in cases where
  // the last phase's coupons has a duration that extends beyond the phase's
  // end date.
  const requiresTermination = !promotionPhases[
    promotionPhases.length - 1
  ]?.promotionIds.some((id) => {
    promotions.find((p) => p.id === id)?.promotionSchedule.type === 'ongoing';
  });
  if (requiresTermination) {
    promotionPhases.push({
      discounts: [],
      intervalCount: 1,
      price: plan.basePrice,
      durationDescription: 'thereafter',
      appliedDiscounts: [],
      promotionIds: [],
    });
  }

  return promotionPhases;
}
