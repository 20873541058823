import { useEffect, useRef, useState } from 'react';
import { useReferralCode } from '~/utils/referralCode';
import type { Plan, Promotion } from './rollerup.server';

interface PlansData {
  plans: Plan[];
  referralPromotion?: Promotion;
  referralCode: string | null;
}

// Use this hook client-side to lookup/add locally-persisted promotion codes to
// the plans
export function usePlansData(initialData: PlansData) {
  const [data, setData] = useState(initialData);
  const fetching = useRef(false);

  const [referralCode] = useReferralCode();

  useEffect(() => {
    const dataReferralCode = data?.referralCode ?? null;
    if (referralCode === dataReferralCode || fetching.current) {
      return;
    }
    const params = new URLSearchParams();
    if (referralCode) {
      params.set('rc', referralCode);
    }
    const plansUrl = '/api/plans?' + params.toString();
    const fetchPlans = async () => {
      fetching.current = true;
      try {
        const response = await fetch(plansUrl);
        if (!response.ok) {
          throw new Error(
            `Failed to fetch plans (response code ${response.status})`,
          );
        }
        setData(await response.json());
      } catch (error) {
        console.error(error);
      } finally {
        fetching.current = false;
      }
    };
    fetchPlans();
  }, [referralCode, data?.referralCode]);

  return data;
}

export type BillingBasis = 'monthly' | 'quarterly' | 'semiannual' | 'annual';
export function getBillingBasis({
  interval,
  intervalCount,
}: Plan): BillingBasis {
  if (interval === 'year' && intervalCount === 1) return 'annual';
  if (interval === 'month' && intervalCount === 6) return 'semiannual';
  if (interval === 'month' && intervalCount === 3) return 'quarterly';
  if (interval === 'month' && intervalCount === 1) return 'monthly';
  throw new Error('Unsupported billing interval');
}

export type BillingInterval = 'month' | 'quarter' | 'half-year' | 'year';
export function getBillingInterval(plan: Plan): BillingInterval {
  const basis = getBillingBasis(plan);
  switch (basis) {
    case 'monthly':
      return 'month';
    case 'quarterly':
      return 'quarter';
    case 'semiannual':
      return 'half-year';
    case 'annual':
      return 'year';
  }
}
