import { getBillingInterval } from '~/utils/plans';
import { AppliedPromotionPhase, applyPromotions } from '~/utils/promotions';
import { Plan } from '~/utils/rollerup.server';

const CheckIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
    className="size-6"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="m4.5 12.75 6 6 9-13.5"
    />
  </svg>
);

function classNames(...classes: string[]) {
  return classes.join(' ');
}

export default function PlanOption({
  plan,
  order,
  href,
}: {
  plan: Plan;
  order: number;
  href?: string;
}) {
  const { id, featured, name, basePrice, features } = plan;
  const promotionPhases = applyPromotions(plan);
  const isPromotion = promotionPhases.length > 0;

  const billingInterval = getBillingInterval(plan);

  let pricePerInterval = basePrice / 100;
  let pricePerMonth = basePrice / 100;

  if (billingInterval === 'quarter') pricePerMonth /= 3;
  if (billingInterval === 'year') {
    pricePerMonth /= 12;
    pricePerInterval = pricePerMonth * 12;
  } else {
    pricePerInterval = pricePerMonth * plan.intervalCount;
  }

  let pricePerIntervalText = '$' + pricePerInterval.toFixed(2);
  if (billingInterval === 'year') pricePerIntervalText += ' billed yearly';
  if (billingInterval === 'quarter')
    pricePerIntervalText += ' billed quarterly';
  if (billingInterval === 'month') pricePerIntervalText = '';

  return (
    <div
      className={classNames(
        plan.featured
          ? 'relative bg-white shadow-2xl'
          : 'bg-white/60 sm:mx-8 lg:mx-0',
        plan.featured
          ? ''
          : order === 1
            ? 'rounded-t-3xl sm:rounded-b-none lg:rounded-bl-3xl lg:rounded-tr-none'
            : 'sm:rounded-t-none lg:rounded-bl-none lg:rounded-tr-3xl',
        'rounded-3xl p-8 ring-1 ring-gray-900/10 sm:p-10 mb-10',
      )}
    >
      <h3 id={id} className="text-base font-semibold leading-7 text-[#FE5000]">
        {name}
      </h3>
      <p className="mt-4 flex flex-col items-start gap-y-1">
        <span
          className={classNames(
            'text-5xl tracking-tight',
            isPromotion
              ? 'line-through decoration-red-500 font-normal text-gray-500'
              : 'font-bold text-gray-900',
          )}
        >
          ${pricePerMonth.toFixed(2)} / month
        </span>
        <span className="text-base text-gray-500">{pricePerIntervalText}</span>
      </p>
      {promotionPhases.map((phase, idx) => (
        <div key={idx}>
          {idx > 0 && idx === promotionPhases.length - 1 ? (
            <p className="text-sm text-gray-500 mt-4">
              then ${phase.price / 100}/{billingInterval}{' '}
              {phase.durationDescription}
            </p>
          ) : (
            <PromotionalPrice phase={phase} plan={plan} />
          )}
          {idx < promotionPhases.length - 2 && (
            <p className="text-sm text-gray-500 mt-4">then</p>
          )}
        </div>
      ))}
      <ul className="mt-8 space-y-3 text-sm leading-6 text-gray-600 sm:mt-10">
        {features.map((feature) => (
          <li key={feature} className="flex gap-x-3">
            {CheckIcon}
            {feature}
          </li>
        ))}
      </ul>
      {href && (
        <a
          href={href}
          aria-describedby={id}
          className={classNames(
            featured
              ? 'bg-[#FE5000] text-white shadow hover:bg-orange-400'
              : 'text-[#FE5000] ring-1 ring-inset ring-orange-200 hover:ring-orange-300',
            'mt-8 block rounded-md px-3.5 py-2.5 text-center text-sm font-semibold focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:mt-10',
          )}
        >
          Get started today
        </a>
      )}
    </div>
  );
}

export function PromotionalPrice({
  phase: { price, durationDescription, promotionIds },
  plan,
}: {
  phase: AppliedPromotionPhase;
  plan: Plan;
}) {
  const billingInterval = getBillingInterval(plan);
  return (
    <div className="flex items-start gap-x-2 mt-4">
      <div>
        <p className="flex items-baseline gap-x-2">
          {price <= 0 ? (
            <span
              className={classNames(
                'text-5xl font-bold tracking-tight text-gray-900',
              )}
            >
              Free
            </span>
          ) : (
            <>
              <span
                className={classNames(
                  'text-5xl font-bold tracking-tight text-gray-900',
                )}
              >
                ${price <= 0 ? 'Free' : price / 100}
              </span>
              <span className="text-base text-gray-500">
                /{billingInterval}
              </span>
            </>
          )}
        </p>
        <p className="flex items-baseline gap-x-2">
          <span className="text-xl font-bold text-[#FE5000]">
            {durationDescription}
          </span>
        </p>
      </div>
      {promotionIds.includes('referral') && (
        <div className="text-xs text-center font-bold text-gray-500 bg-[#FCB900] items-start rounded px-2 py-1">
          REFERRAL DISCOUNT
        </div>
      )}
    </div>
  );
}
